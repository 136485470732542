import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class CursosEn extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle} lang="en">
        <SEO title="Courses" />
        <div class="relative px-4 sm:px-6 lg:px-8 bg-white rounded-md py-8">
          <div class="text-lg max-w-prose mx-auto">
            <h1>
              <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                There are no courses yet.
              </span>
            </h1>
            <p class="mt-8 text-xl text-gray-800 leading-8">
              However, I am planning to release some courses at some point in
              English as well, so please,{" "}
              <span class="text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl">
                keep up to date!
              </span>
            </p>
            <Link
              type="button"
              href="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-4"
            >
              Go to the main page
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CursosEn;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
